import { CheckCircleTwoTone, MoreOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import {
  Badge,
  Button,
  Checkbox,
  Col,
  Divider,
  Dropdown,
  Empty,
  Popover,
  Row,
  Table,
  Tag,
  message
} from 'antd';
import { capitalize, debounce, forEach, isEmpty, map } from 'lodash';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import FilterSelectedIconComponent from '../../../app/components/iconComponents/FilterSelectedComponent';
import ExportIcon from '../../../assets/export.svg';
import FilterIcon from '../../../assets/filter.svg';
import placeholderImage from '../../../assets/images/place-holder-image-master.svg';
import { ROUTES, SKIP_RECORD } from '../../../common/constants';
import {
  checkPermissions,
  dateFormatWithoutTime,
  formatDate,
  formatPrice,
  handleExportCommon,
  objectWithoutKey
} from '../../../common/utils';
import AccessControl from '../../../components/AccessControl';
import LoaderComponent from '../../../components/LoaderComponent';
import PriceComponent from '../../../components/PriceComponent';
import RangePickerComponent from '../../../components/RangePickerComponent';
import SearchComponent from '../../../components/SearchComponent';
import TableComponent from '../../../components/TableComponent';
import history from '../../../historyData';
import { BRAND_FILTER } from '../../myQuestionnaire/graphql/Queries';
import { REGION_FILTER } from '../../regions/graphql/Queries';
import {
  GET_LEADS,
  INDUSTRY_FILTER,
  LINE_OF_BUSINESS_FILTER,
  SUB_AREA_FILTER
} from '../graphql/Queries';

let scrollDebounce = null;

const QuoteViewerTable = () => {
  const {
    state: { pageSize, filterData, globalDateFormat, permissions },
    dispatch
  } = useContext(AppContext);

  const initialPaginationValue = {
    total: 0,
    current: 1
  };

  const initialQuoteViewerFilter = {
    skip: 0,
    limit: pageSize,
    sortOn: 'createdAt',
    sortBy: 'DESC'
  };

  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [sortedInfo, setSortedInfo] = useState({});
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [quoteViewerFilter, setQuoteViewerFilter] = useState(
    initialQuoteViewerFilter
  );
  const [searchValue, setSearchValue] = useState(null);
  const [filters, setFilters] = useState(null);
  const [filterSearch, setFilterSearch] = useState('');
  const [filterLoading, setFilterLoading] = useState(false);
  const [filterList, setFilterList] = useState([]);
  const [scrollFlag, setScrollFlag] = useState(false);
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterIndex, setFilterIndex] = useState(null);
  const [exportLoading, setExportLoading] = useState(false);
  const [filtersCopyState, setFiltersCopyState] = useState(null);
  const [filterIsEnd, setFilterIsEnd] = useState(false);

  const [leadViews, { loading, data }] = useLazyQuery(GET_LEADS, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const pagination = {
        ...paginationProp,
        defaultPageSize: pageSize,
        total: res?.leads?.count
      };
      setPaginationProp(pagination);
    },
    onError() {}
  });

  const [industryFilters] = useLazyQuery(INDUSTRY_FILTER, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (scrollFlag) {
        const optionsCopy = [...filterList];
        forEach(res?.industryFilters?.data, (item) =>
          optionsCopy?.push({ label: item?.label, key: item?.key })
        );
        setFilterList(optionsCopy);
        setScrollFlag(false);
      } else {
        const optionsCopy = [];
        forEach(res?.industryFilters?.data, (item) =>
          optionsCopy?.push({ label: item?.label, key: item?.key })
        );
        setFilterList(optionsCopy);
      }
      setFilterIsEnd(res?.industryFilters?.data?.length < SKIP_RECORD);
      setFilterLoading(false);
    },
    onError: () => {
      setFilterLoading(false);
    }
  });

  const [lineOfBusinessFilters] = useLazyQuery(LINE_OF_BUSINESS_FILTER, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (scrollFlag) {
        const optionsCopy = [...filterList];
        forEach(res?.lineOfBusinessFilters?.data, (item) =>
          optionsCopy?.push({ label: item?.label, key: item?.label })
        );
        setFilterList(optionsCopy);
        setScrollFlag(false);
      } else {
        const optionsCopy = [];
        forEach(res?.lineOfBusinessFilters?.data, (item) =>
          optionsCopy?.push({ label: item?.label, key: item?.label })
        );
        setFilterList(optionsCopy);
      }
      setFilterIsEnd(res?.lineOfBusinessFilters?.data?.length < SKIP_RECORD);
      setFilterLoading(false);
    },
    onError: () => {
      setFilterLoading(false);
    }
  });

  const [subAreaFilters] = useLazyQuery(SUB_AREA_FILTER, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (scrollFlag) {
        const optionsCopy = [...filterList];
        forEach(res?.subAreaFilters?.data, (item) =>
          optionsCopy?.push(item?.label)
        );
        setFilterList(optionsCopy);
        setScrollFlag(false);
      } else {
        const optionsCopy = [];
        forEach(res?.subAreaFilters?.data, (item) =>
          optionsCopy?.push(item?.label)
        );
        setFilterList(optionsCopy);
      }
      setFilterIsEnd(res?.subAreaFilters?.data?.length < SKIP_RECORD);
      setFilterLoading(false);
    },
    onError: () => {
      setFilterLoading(false);
    }
  });

  const [brandFilters] = useLazyQuery(BRAND_FILTER, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (scrollFlag) {
        const optionsCopy = [...filterList];
        forEach(res?.brandFilters?.data, (item) =>
          optionsCopy?.push({ label: item?.name, key: item?.name })
        );
        setFilterList(optionsCopy);
        setScrollFlag(false);
      } else {
        const optionsCopy = [];
        forEach(res?.brandFilters?.data, (item) =>
          optionsCopy?.push({ label: item?.name, key: item?.name })
        );
        setFilterList(optionsCopy);
      }
      setFilterIsEnd(res?.questionnaireFilters?.data?.length < SKIP_RECORD);
      setFilterLoading(false);
    },
    onError: () => {
      setFilterLoading(false);
    }
  });

  const [regionFilters] = useLazyQuery(REGION_FILTER, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (scrollFlag) {
        const optionsCopy = [...filterList];
        forEach(res?.regionFilters?.data, (item) =>
          optionsCopy?.push({ label: item?.name, key: item?.name })
        );
        setFilterList(optionsCopy);
        setScrollFlag(false);
      } else {
        const optionsCopy = [];
        forEach(res?.regionFilters?.data, (item) =>
          optionsCopy?.push({ label: item?.name, key: item?.name })
        );
        setFilterList(optionsCopy);
      }
      setFilterIsEnd(res?.regionFilters?.data?.length < SKIP_RECORD);
      setFilterLoading(false);
    },
    onError: () => {
      setFilterLoading(false);
    }
  });

  useEffect(() => {
    leadViews({
      variables: {
        filter: quoteViewerFilter,
        ...(filterData && { where: filterData })
      }
    });
    setFilters(filterData);
    dispatch({ type: 'SET_FILTER_DATA', data: null });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getFilterData = (confirm, dataIndex) => {
    if (dataIndex === 'amount') {
      if (
        !filtersCopyState?.amount?.min?.toString() ||
        !filtersCopyState?.amount?.max?.toString()
      ) {
        message?.destroy();
        message?.error('Please enter values');
        return;
      }
      if (filtersCopyState?.amount?.min < 0) {
        return message?.error('Min value can not be less than zero');
      }
      if (filtersCopyState?.amount?.max < 0) {
        return message?.error('Max value can not be less than zero');
      }

      if (filtersCopyState?.amount?.min >= filtersCopyState?.amount?.max) {
        message?.destroy();
        message?.error(
          'Min value can not be greater than or equal to max value'
        );
        return;
      }
    }

    const { brand, ...otherFilterCopyState } = filtersCopyState;
    leadViews({
      variables: {
        filter: { ...quoteViewerFilter, skip: 0 },
        ...(otherFilterCopyState && {
          where: { ...otherFilterCopyState, retailer: filtersCopyState?.brand }
        })
      }
    });

    setFilters(filtersCopyState);
    setQuoteViewerFilter({
      ...quoteViewerFilter,
      skip: 0
    });
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    if (confirm) {
      confirm();
    }
  };

  useEffect(() => {
    if (filterVisible) {
      setFilterList([]);
      setFilterLoading(true);
      switch (filterIndex) {
        case 'industry':
          industryFilters({
            variables: {
              filter: {
                sortOn: 'label',
                sortBy: 'ASC',
                skip: 0,
                limit: 20,
                search: filterSearch,
                getDBField: 'label'
              }
            }
          });
          break;

        case 'lineOfBusiness':
          lineOfBusinessFilters({
            variables: {
              filter: {
                sortOn: 'label',
                sortBy: 'ASC',
                skip: 0,
                limit: 20,
                search: filterSearch,
                justShow: true,
                getDBField: 'label'
              }
            }
          });
          break;

        case 'subArea':
          subAreaFilters({
            variables: {
              filter: {
                sortOn: 'label',
                sortBy: 'ASC',
                skip: 0,
                limit: 20,
                search: filterSearch,
                justShow: true,
                getDBField: 'label'
              }
            }
          });
          break;

        case 'brand':
          brandFilters({
            variables: {
              filter: {
                sortOn: 'name',
                sortBy: 'ASC',
                skip: 0,
                limit: 20,
                search: filterSearch,
                distinct: true,
                getDBField: 'name'
              }
            }
          });
          break;

        case 'region':
          regionFilters({
            variables: {
              filter: {
                sortOn: 'name',
                sortBy: 'ASC',
                skip: 0,
                limit: 20,
                search: filterSearch,
                distinct: true,
                getDBField: 'name'
              }
            }
          });
          break;

        default:
          if (filterIndex === 'exportStatus') {
            const optionsCopy = [
              {
                key: 'SUCCESS',
                label: 'Success'
              },
              {
                key: 'PENDING',
                label: 'Pending'
              }
            ];
            setFilterList(optionsCopy);
          }
          setFilterLoading(false);
          break;
      }
    }
    if (!filterVisible) {
      setFiltersCopyState(filters);
      setFilterSearch('');
      setFilterIsEnd(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterVisible]);

  useEffect(() => {
    const permitted = checkPermissions(permissions, ['FET_QUOTE_VIEW_LIST']);
    const whereFilter = history?.location?.state;
    let parsedObject;

    if (!isEmpty(whereFilter)) {
      parsedObject = JSON?.parse(whereFilter?.stringifyObject);
    }

    const { brand, ...otherFilter } = parsedObject?.filters ?? {};

    if (permitted) {
      leadViews({
        variables: {
          filter: parsedObject?.quoteViewerFilter ?? quoteViewerFilter,
          ...(parsedObject && {
            where: { ...otherFilter, retailer: brand }
          })
        }
      });
      if (!isEmpty(parsedObject)) {
        setFilters({ ...parsedObject?.filters });
        setFiltersCopyState({ ...parsedObject?.filters });
        setSearchValue(parsedObject?.quoteViewerFilter?.search);
        const sorter = {
          order:
            parsedObject?.quoteViewerFilter?.sortBy === 'ASC'
              ? 'ascend'
              : 'descend',
          columnKey: parsedObject?.quoteViewerFilter?.sortOn
        };
        setSortedInfo({ ...sorter });
        setQuoteViewerFilter({ ...parsedObject?.quoteViewerFilter });
        setPaginationProp({ ...parsedObject?.paginationProp });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onScroll = (event, dataIndex) => {
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    if (scrollDebounce) {
      scrollDebounce?.cancel();
      scrollDebounce = null;
    }
    scrollDebounce = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (
        scrolledToBottom &&
        filterIndex === dataIndex &&
        filterVisible &&
        !filterIsEnd
      ) {
        setScrollFlag(true);
        switch (filterIndex) {
          case 'industry':
            industryFilters({
              variables: {
                filter: {
                  sortOn: 'label',
                  sortBy: 'ASC',
                  skip: filterList?.length,
                  limit: 20,
                  search: filterSearch,
                  getDBField: 'label'
                }
              }
            });
            break;

          case 'lineOfBusiness':
            lineOfBusinessFilters({
              variables: {
                filter: {
                  sortOn: 'label',
                  sortBy: 'ASC',
                  skip: filterList?.length,
                  limit: 20,
                  search: filterSearch,
                  justShow: true,
                  getDBField: 'label'
                }
              }
            });
            break;

          case 'subArea':
            subAreaFilters({
              variables: {
                filter: {
                  sortOn: 'label',
                  sortBy: 'ASC',
                  skip: filterList?.length,
                  limit: 20,
                  search: filterSearch,
                  justShow: true,
                  getDBField: 'label'
                }
              }
            });
            break;

          case 'brand':
            brandFilters({
              variables: {
                filter: {
                  sortOn: 'name',
                  sortBy: 'ASC',
                  skip: filterList?.length,
                  limit: 20,
                  search: filterSearch,
                  distinct: true,
                  getDBField: 'name'
                }
              }
            });
            break;

          case 'region':
            regionFilters({
              variables: {
                filter: {
                  sortOn: 'name',
                  sortBy: 'ASC',
                  skip: filterList?.length,
                  limit: 20,
                  search: filterSearch,
                  distinct: true,
                  getDBField: 'name'
                }
              }
            });
            break;

          default:
            setFilterLoading(false);
            break;
        }
      }
    }, 500);
    scrollDebounce();
  };

  const rowSelection = {
    fixed: 'left',
    columnWidth: 50,
    selectedRowKeys: selectedKeys,
    onChange: (selectedRowKeys) => {
      setSelectedKeys(selectedRowKeys);
    },
    renderCell(checked, record, index, node) {
      return React.cloneElement(node, {
        'aria-label': record?.id
      });
    }
  };

  const handleReset = (clearFilters, dataIndex) => {
    const filtersCopy = {
      ...filters,
      [dataIndex]: ''
    };
    delete filtersCopy?.[dataIndex];

    setFilters(filtersCopy);
    const { brand, ...otherFilterCopyState } = filtersCopy;
    leadViews({
      variables: {
        filter: { ...quoteViewerFilter, skip: 0 },
        ...(otherFilterCopyState && {
          where: { ...otherFilterCopyState, retailer: filtersCopy?.brand }
        })
      }
    });
    setQuoteViewerFilter({
      ...quoteViewerFilter,
      skip: 0
    });
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    clearFilters();
    setFilterIndex(dataIndex);
    setFilterVisible(false);
  };

  const changeFilter = (e, dataIndex) => {
    const {
      target: { value = '' }
    } = e;
    let filtersCopy = [];

    if (filtersCopyState?.[dataIndex]?.includes(value)) {
      filtersCopy = {
        ...filtersCopyState,
        [dataIndex]:
          dataIndex === 'brand' ||
          dataIndex === 'region' ||
          dataIndex === 'exportStatus' ||
          dataIndex === 'lineOfBusiness'
            ? value
            : value.label
      };
    } else {
      filtersCopy = {
        ...filtersCopyState,
        [dataIndex]:
          dataIndex === 'brand' ||
          dataIndex === 'region' ||
          dataIndex === 'exportStatus' ||
          dataIndex === 'lineOfBusiness'
            ? value
            : value.label
      };
    }

    setFiltersCopyState(filtersCopy);
  };

  const handleTableChange = (pagination, tableFilter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination?.pageSize;
    setSortedInfo(sorter);
    setPaginationProp({ ...paginationProp, ...pagination });
    if (sorter?.column) {
      setQuoteViewerFilter({
        ...quoteViewerFilter,
        skip,
        limit: pagination?.pageSize,
        sortOn: sorter?.field,
        sortBy: sorter?.order === 'ascend' ? 'ASC' : 'DESC'
      });
      leadViews({
        variables: {
          filter: {
            ...quoteViewerFilter,
            skip,
            limit: pagination?.pageSize,
            sortOn: sorter?.field,
            sortBy: sorter?.order === 'ascend' ? 'ASC' : 'DESC'
          },
          ...(filters && {
            where: {
              ...objectWithoutKey(filters, 'brand'),
              retailer: filters?.brand
            }
          })
        }
      });
    } else {
      setQuoteViewerFilter({
        ...quoteViewerFilter,
        skip,
        limit: pagination?.pageSize,
        sortOn: 'createdAt',
        sortBy: 'DESC'
      });
      leadViews({
        variables: {
          filter: {
            ...quoteViewerFilter,
            skip,
            limit: pagination?.pageSize,
            sortOn: 'createdAt',
            sortBy: 'DESC'
          },
          ...(filters && {
            where: {
              ...objectWithoutKey(filters, 'brand'),
              retailer: filters?.brand
            }
          })
        }
      });
    }
  };

  const handleSearch = (value, dataIndex) => {
    setFilterSearch(value);
    setScrollFlag(false);
    switch (dataIndex) {
      case 'industry':
        industryFilters({
          variables: {
            filter: {
              sortOn: 'label',
              sortBy: 'ASC',
              skip: 0,
              limit: 20,
              search: filterSearch,
              getDBField: 'label'
            }
          }
        });
        break;

      case 'lineOfBusiness':
        lineOfBusinessFilters({
          variables: {
            filter: {
              sortOn: 'label',
              sortBy: 'ASC',
              skip: 0,
              limit: 20,
              search: value,
              justShow: true,
              getDBField: 'label'
            }
          }
        });
        break;

      case 'subArea':
        subAreaFilters({
          variables: {
            filter: {
              sortOn: 'label',
              sortBy: 'ASC',
              skip: 0,
              limit: 20,
              search: value,
              justShow: true,
              getDBField: 'label'
            }
          }
        });
        break;

      case 'brand':
        brandFilters({
          variables: {
            filter: {
              sortOn: 'name',
              sortBy: 'ASC',
              skip: 0,
              limit: 20,
              search: value,
              distinct: true,
              getDBField: 'name'
            }
          }
        });
        break;

      case 'region':
        regionFilters({
          variables: {
            filter: {
              sortOn: 'name',
              sortBy: 'ASC',
              skip: 0,
              limit: 20,
              search: value,
              distinct: true,
              getDBField: 'name'
            }
          }
        });
        break;

      default:
        setFilterLoading(false);
        break;
    }
  };

  const handleChangeAmount = (value, input, dataIndex) => {
    const filtersCopy = {
      ...filtersCopyState,
      [dataIndex]: { ...filtersCopyState?.[dataIndex], [input]: value }
    };
    setFiltersCopyState(filtersCopy);
  };

  const handleDeleteFilter = (value, dataIndex) => {
    const filtersCopy = {
      ...filtersCopyState,
      [dataIndex]: dataIndex === 'exportStatus' ? value : value.label
    };
    delete filtersCopy?.[dataIndex];

    setFiltersCopyState(filtersCopy);
  };

  const onRangePickerChange = (values, dataIndex) => {
    const rangeObj = {
      from: moment(values?.[0])?.startOf('day'),
      to: moment(values?.[1])?.endOf('day')
    };

    const filtersCopy = {
      ...filtersCopyState,
      [dataIndex]: rangeObj
    };
    if (!values?.length) {
      delete filtersCopy?.[dataIndex];
    }
    setFiltersCopyState(filtersCopy);
  };

  const filterPopup = (dataIndex) => ({
    filterDropdown: ({ confirm, clearFilters }) => {
      if (dataIndex === 'createdAt') {
        return (
          <div className="custom-filter-dropdown range-filter-dropdown">
            <RangePickerComponent
              format={dateFormatWithoutTime(globalDateFormat)}
              allowClear={false}
              value={
                filtersCopyState?.[dataIndex]
                  ? [
                      filtersCopyState?.[dataIndex]?.from,
                      filtersCopyState?.[dataIndex]?.to
                    ]
                  : []
              }
              onChange={(values) => onRangePickerChange(values, dataIndex)}
            />
            <Divider className="divider-filter" />
            <div className="d-flex justify-center">
              <Button
                size="small"
                className="common-button discard-button filter-button"
                id="date-filter-reset"
                onClick={() => handleReset(clearFilters, dataIndex)}
              >
                Reset
              </Button>
              <Button
                size="small"
                className="common-button filter-button"
                id="date-filter-ok"
                type="primary"
                onClick={() => getFilterData(confirm, dataIndex)}
              >
                Ok
              </Button>
            </div>
          </div>
        );
      }
      if (dataIndex === 'amount') {
        return (
          <div className="custom-filter-dropdown amount-filter-dropdown">
            <Row gutter={[8, 8]}>
              <Col span={12}>
                <PriceComponent
                  value={
                    filtersCopyState?.amount
                      ? filtersCopyState?.amount?.min
                      : false
                  }
                  onChange={(value) =>
                    handleChangeAmount(value, 'min', dataIndex)
                  }
                  placeholder="min"
                />
              </Col>
              <Col span={12}>
                <PriceComponent
                  value={
                    filtersCopyState?.amount
                      ? filtersCopyState?.amount?.max
                      : false
                  }
                  onChange={(value) =>
                    handleChangeAmount(value, 'max', dataIndex)
                  }
                  placeholder="max"
                />
              </Col>
            </Row>
            <Divider className="divider-filter" />
            <div className="d-flex justify-center">
              <Button
                size="small"
                className="common-button discard-button filter-button"
                id="date-filter-reset"
                onClick={() => handleReset(clearFilters, dataIndex)}
              >
                Reset
              </Button>
              <Button
                size="small"
                className="common-button filter-button"
                id="date-filter-ok"
                htmlType="submit"
                type="primary"
                onClick={() => getFilterData(confirm, dataIndex)}
              >
                Ok
              </Button>
            </div>
          </div>
        );
      }
      return (
        <div className="custom-filter-dropdown">
          <LoaderComponent spinning={filterLoading} setHeight={35}>
            {dataIndex !== 'exportStatus' && (
              <SearchComponent
                className="list-search-box filter-search"
                id="search-container-id-roles"
                placeholder="Search..."
                name={dataIndex}
                getData={(value) => handleSearch(value, dataIndex)}
                value={searchValue}
                setSearchValue={setSearchValue}
              />
            )}
            {filtersCopyState?.[dataIndex] && (
              <Tag
                key={filtersCopyState?.[dataIndex]?.toString()}
                closable
                onClose={() =>
                  handleDeleteFilter(filtersCopyState?.[dataIndex], dataIndex)
                }
                className="filter-tag"
              >
                {dataIndex === 'exportStatus' ? (
                  <span
                    title={
                      filtersCopyState?.[dataIndex] === 'SUCCESS'
                        ? 'Success'
                        : 'Pending'
                    }
                  >
                    {filtersCopyState?.[dataIndex] === 'SUCCESS'
                      ? 'Success'
                      : 'Pending'}
                  </span>
                ) : (
                  filtersCopyState?.[dataIndex] && (
                    <span title={filtersCopyState?.[dataIndex]?.toString()}>
                      {filtersCopyState?.[dataIndex]?.toString()}
                    </span>
                  )
                )}
              </Tag>
            )}
            <div
              className="filter-checkboxes"
              onScroll={(e) => onScroll(e, dataIndex)}
            >
              {filterList?.length > 0 ? (
                map(filterList, (item) => {
                  const checkLabel =
                    dataIndex === 'exportStatus' ? item?.key : item?.label;
                  return (
                    <div
                      className="filter-checkbox-section"
                      key={item?.key || item}
                    >
                      <Checkbox
                        value={item?.key || item}
                        checked={
                          filtersCopyState?.[dataIndex] &&
                          checkLabel === filtersCopyState?.[dataIndex]
                        }
                        key={item?.key || item}
                        onChange={(e) => changeFilter(e, dataIndex)}
                        className="common-checkbox"
                      >
                        <span title={item?.label || item?.toString()}>
                          {item?.label || item?.toString()}
                        </span>
                      </Checkbox>
                    </div>
                  );
                })
              ) : (
                <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
              )}
            </div>
          </LoaderComponent>
          <Divider className="divider-filter" />
          <div className="d-flex justify-center">
            <Button
              size="small"
              className="common-button discard-button filter-button"
              id="roles-filter-reset"
              onClick={() => handleReset(clearFilters, dataIndex)}
            >
              Reset
            </Button>
            <Button
              size="small"
              className="common-button filter-button"
              id="roles-filter-ok"
              type="primary"
              onClick={() => getFilterData(confirm, dataIndex)}
            >
              Ok
            </Button>
          </div>
        </div>
      );
    },
    filterIcon: () =>
      filters?.[dataIndex]?.length > 0 || !isEmpty(filters?.[dataIndex]) ? (
        <FilterSelectedIconComponent className="primary-color" />
      ) : (
        <img src={FilterIcon} alt="filter-icon" width={16} />
      ),
    onFilterDropdownOpenChange: (visible) => {
      if (dataIndex === 'exportStatus') {
        setFilterList([]);
      }
      setFilterIndex(dataIndex);
      setFilterVisible(visible);
    }
  });

  const renderActionButtons = (record) => {
    return (
      <div className="d-flex flex-vertical">
        <AccessControl allowedPermissions={['FET_QUOTE_VIEW_VIEW']}>
          <Button
            id="manufacturer-table-edit-btn"
            className="b-0"
            onClick={() => {
              const otherFilters = {
                quoteViewerFilter,
                paginationProp,
                filters
              };
              const stringifyObject = JSON?.stringify(otherFilters);
              return history.push(`${ROUTES.QUOTE_VIEWER}/view/${record?.id}`, {
                stringifyObject
              });
            }}
          >
            View
          </Button>
        </AccessControl>
      </div>
    );
  };

  const columns = [
    Table?.SELECTION_COLUMN,
    {
      title: 'DATE & TIME',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: true,
      width: 200,
      fixed: 'left',
      ellipsis: true,
      sortOrder: sortedInfo?.columnKey === 'createdAt' && sortedInfo?.order,
      className: 'max-width-column date-column-min-width',
      ...filterPopup('createdAt'),
      render: (updatedAt) => {
        if (updatedAt) {
          return <span>{formatDate(updatedAt, globalDateFormat)}</span>;
        }
        return '-';
      }
    },
    {
      title: 'QUESTIONNAIRE ',
      dataIndex: 'questionnaire',
      key: 'questionnaireID',
      align: 'center',
      ellipsis: true,
      width: 190,
      render: (record = {}) => {
        return <span>{record?.title || '-'}</span>;
      }
    },
    {
      title: 'INDUSTRY',
      dataIndex: 'industry',
      key: 'industry',
      sorter: true,
      ellipsis: true,
      sortOrder: sortedInfo?.columnKey === 'industry' && sortedInfo?.order,
      width: 190,
      ...filterPopup('industry'),
      render: (industry, record = {}) => <span>{record?.industry?.label}</span>
    },
    {
      title: 'CUSTOMER NAME',
      dataIndex: 'customerName',
      key: 'customerName',
      sorter: true,
      ellipsis: true,
      width: 200,
      sortOrder: sortedInfo?.columnKey === 'customerName' && sortedInfo?.order,
      render: (customerName) => <span>{customerName || '-'}</span>
    },
    {
      title: 'RETAILERS',
      dataIndex: 'retailer',
      key: 'retailer',
      sorter: true,
      width: 150,
      className: 'max-width-column',
      ellipsis: true,
      sortOrder: sortedInfo?.columnKey === 'retailer' && sortedInfo?.order,
      ...filterPopup('brand'),
      render: (retailer, record) => {
        return (
          <>
            <img
              src={record?.brand?.logo?.url || placeholderImage}
              alt="brand-img"
              onError={(e) => {
                e.target.src = placeholderImage;
              }}
              className="list-img image-contain"
              width={24}
              height={24}
            />
            <span title={record?.brand?.name}>{record?.brand?.name}</span>
          </>
        );
      }
    },
    {
      title: 'REGION',
      dataIndex: 'region',
      key: 'region',
      sorter: true,
      ellipsis: true,
      align: 'center',
      className: 'max-width-column',
      sortOrder: sortedInfo?.columnKey === 'region' && sortedInfo?.order,
      width: 120,
      ...filterPopup('region'),
      render: (region, record) => <span>{record?.region?.name || '-'}</span>
    },
    {
      title: 'QUOTE AMT.',
      dataIndex: 'amount',
      key: 'amount',
      sorter: true,
      align: 'center',
      ellipsis: true,
      sortOrder: sortedInfo?.columnKey === 'amount' && sortedInfo?.order,
      width: 150,
      ...filterPopup('amount'),
      render: (amount = null) => (
        <span title={formatPrice(amount)}>
          {amount ? formatPrice(amount) : '-'}
        </span>
      )
    },
    {
      title: 'CAMPAIGN CODE',
      dataIndex: 'campaign',
      key: 'campaign',
      align: 'center',
      width: 170,
      ellipsis: true,
      render: (campaign) => {
        const { id, code } = campaign ?? {};
        return (
          <Link
            className="link-btn-campaign-code"
            to={`${ROUTES?.CAMPAIGNS}/edit/${id}`}
          >
            {capitalize(code || '-')}
          </Link>
        );
      }
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: 170,
      ellipsis: true,
      render: (status) => {
        return capitalize(status || '-');
      }
    },
    {
      title: 'LEAD/OPPTY/CRM ID',
      dataIndex: 'id',
      key: 'id',
      ellipsis: true,
      className: 'max-width-column'
    },
    {
      title: 'EXPORT STATUS',
      dataIndex: 'exportStatus',
      key: 'exportStatus',
      fixed: 'right',
      width: 170,
      sorter: true,
      ellipsis: true,
      align: 'left',
      sortOrder: sortedInfo?.columnKey === 'exportStatus' && sortedInfo?.order,
      ...filterPopup('exportStatus'),
      render: (exportStatus) => {
        if (exportStatus === 'SUCCESS') {
          return (
            <div>
              <CheckCircleTwoTone twoToneColor="#52C41A" />
              <span className="success-color success-text">Success</span>
            </div>
          );
        }

        return (
          <Badge
            color="#FF8845"
            text="Pending"
            className="pending-color pending-text"
          />
        );
      }
    },
    checkPermissions(permissions, ['FET_QUOTE_VIEW_VIEW']) && {
      dataIndex: 'id',
      align: 'right',
      width: 10,
      fixed: 'right',
      render: (id, record) => {
        return (
          <Popover
            placement="bottom"
            overlayClassName="action-button"
            content={renderActionButtons(record)}
          >
            <MoreOutlined />
          </Popover>
        );
      }
    }
  ];

  const onSearchChange = (value) => {
    setQuoteViewerFilter({
      ...quoteViewerFilter,
      skip: value
        ? 0
        : quoteViewerFilter?.limit * (paginationProp?.current - 1),
      search: value
    });

    leadViews({
      variables: {
        filter: {
          ...quoteViewerFilter,
          skip: value
            ? 0
            : quoteViewerFilter?.limit * (paginationProp?.current - 1),
          search: value
        },
        ...(filters && {
          where: {
            ...objectWithoutKey(filters, 'brand'),
            retailer: filters?.brand
          }
        })
      }
    });
  };

  const handleExport = async (e) => {
    const { key } = e;
    setExportLoading(true);
    await handleExportCommon({
      filters: {
        ...filters,
        ...(key === 'PENDING' && {
          exportStatus: [key]
        })
      },
      key,
      selectedKeys,
      module: 'QUOTE_VIEW'
    });
    setExportLoading(false);
  };

  return (
    <div>
      <div className="mb-16 d-flex justify-between align-center">
        <div className="fill-width search-checkbox">
          <SearchComponent
            className="list-search-box"
            id="search-container-id"
            placeholder="Search all details..."
            name="quoteViewers"
            getData={onSearchChange}
          />
        </div>
        <div className="export-btn-quote">
          <AccessControl allowedPermissions={['FET_QUOTE_VIEW_EXPORT']}>
            <Dropdown
              overlayClassName="export-btn-dropdown"
              menu={{
                items: [
                  {
                    label: 'Selected',
                    title: 'Selected',
                    key: 'SELECTED'
                  },
                  {
                    label: 'Pending',
                    title: 'Pending',
                    key: 'PENDING'
                  },
                  {
                    label: 'All',
                    title: 'All',
                    key: 'ALL'
                  }
                ],
                onClick: handleExport
              }}
              placement="bottom"
            >
              <Button
                className="common-button export-button"
                size="small"
                icon={<img src={ExportIcon} alt="export-icon" width={11} />}
                id="quoteViewer-table-export-btn"
                loading={exportLoading}
                type="primary"
              >
                Export
              </Button>
            </Dropdown>
          </AccessControl>
        </div>
      </div>
      <AccessControl allowedPermissions={['FET_QUOTE_VIEW_LIST']}>
        <div className="common-table">
          {pageSize && (
            <TableComponent
              loadingData={loading}
              columns={[...columns?.filter((item) => item !== false)]}
              data={data?.leads?.data || []}
              onChange={handleTableChange}
              paginationConfig={paginationProp}
              rowSelection={rowSelection}
              rowKey={(obj) => obj?.id}
            />
          )}
        </div>
      </AccessControl>
    </div>
  );
};

export default QuoteViewerTable;
